body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EBF2FC;
  color: white;
  /* background-image: linear-gradient(180deg,#2f6ed3 5%,#5095e4 20%,#5095e4 60%,#2f6ed3 95%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  color: black;
}

p {
  color: black;
}

*{
  /* border: 1px solid red; */
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'), url(./fonts/Montserrat-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'), url(./fonts/Montserrat-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: local('Montserrat'), url(./fonts/Montserrat-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: local('Montserrat'), url(./fonts/Montserrat-ExtraLight.ttf) format('opentype');
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Hide Scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* Hide Scrollbar Firefox */
html {
  scrollbar-width: none;
}

/* Hide Scrollbar IE 10+ */
html {
  -ms-overflow-style: none;
}

/* Hide Scrollbar Safari and Chrome */
body::-webkit-scrollbar {
  display: none;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}